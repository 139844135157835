import React from 'react';
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from '@chakra-ui/layout';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Result, Typography } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Routes } from '../../shared/enums/routes';
import { Link } from 'react-router-dom';
import { FaTelegram, FaTelegramPlane, FaVk } from 'react-icons/fa';

export const SuccessPage = () => {
  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, `${window.location.hostname}_success_PAGE`);
  }, []);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Успешно'
        //subTitle=''

        extra={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {/* <Typography.Title style={{ fontSize: 14, marginTop: 0 }}>
              Спасибо за заявку, позже мы с Вами свяжемся
            </Typography.Title> */}
            <Typography.Paragraph style={{ fontSize: '16px' }} strong>
              Поздравляем! Остался последний шаг!
            </Typography.Paragraph>
            <Typography.Paragraph style={{ fontSize: '16px' }}>
              Обязательно перейдите по ссылке в закрытый ТГ канал, где будет проведена
              <br />
              прямая трансляция с розыгрышем 23 августа и подпишитесь, чтобы не пропустить трансляцию!
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //justifyContent: 'space-between',
                width: '180px',
                alignSelf: 'center',
                marginBottom: '24px',
              }}
            >
              {/* <a href='https://t.me/missislaser'>
                <FaTelegram size={28} color='#0088cc' />
              </a> */}
            </div>
            {/* <a href='https://vk.com/missis_laser'>
                <FaVk size={28} color='#0077ff' />
              </a>
              <a href='https://www.instagram.com/missis_laser/'>
                <img alt='inst' src='inst.png' style={{ width: '24px' }} />
              </a>
              <a href='https://dzen.ru/missis_laser'>
                <img alt='dzen' src='dzen.png' style={{ width: '24px' }} />
              </a> */}
            <a href='https://t.me/missislaser'>
              <ChakraButton size='sm' colorScheme='telegram'>
                <FaTelegramPlane size={28} style={{ marginRight: 8 }} />
                Подписаться на Телеграм
              </ChakraButton>
            </a>
            {/* <Link to={`${Routes.Preparation}`}>
              <ChakraButton size='sm' colorScheme='green'>
                Как подготовится к процедуре?
              </ChakraButton>
            </Link> */}
          </div>
        }
      />
    </Box>
  );
};
